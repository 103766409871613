<template>
  <div>
    <Card class="mt-4">
        <div class="flex items-center justify-between">
            <div class="flex items-center">
                <p class="text-lg font-semibold">KYB details</p>
                <SecondaryButton :text="businessRegistrationInfo ? 'Edit Business Info' : 'Add Business Info'"  
                    @click="handleBusinessInfo" class="ml-2" 
                    :disabled="accountData.businessKybDetails && accountData.businessKybDetails.kycStatus === 'APPROVED'"
                />
                <SecondaryButton  @click="handleAddDirectives" :text="businessDirector.length ?'Edit Director details' :  'Add Directors Details'" 
                    :disabled="!businessRegistrationInfo || !businessRegistrationInfo.id || (accountData.businessKybDetails && accountData.businessKybDetails.kycStatus === 'APPROVED')"
                    class="ml-2" 
                />
            </div>
            <div class="flex items-center">

                <SecondaryButton text="Add rate discount" @click="showDiscountModal = true" 
                    v-if="$route.name == 'single-wire-account'"
                    :disabled="((accountData.wireAccount && accountData.wireAccount.forcedKycStatus === 'NEUTRAL') && (accountData.businessKybDetails && accountData.businessKybDetails.kycStatus != 'APPROVED'))  || (accountData.wireAccount && accountData.wireAccount.forcedKycStatus === 'OFF' )" class="mr-2"/>
                <SecondaryButton text="Approve KYB" @click="showUpdateKybModal = true" :disabled="disableApproveKyc()" />
                <SecondaryButton text="Update broker status" @click="showBrokerAccessModal = true" class="ml-2" v-if="$route.name == 'single-wire-account'"/>
                <SecondaryButton class="ml-2" text="Broker eligibility" @click="showForceUpdateModal = true" v-if="$route.name == 'single-wire-account'"/>
                <SecondaryButton class="ml-2" text="Force update Kyc" @click="showUpdateSendersKyc = true" v-if="$route.name === 'single-sender-info'"/>
            </div>
        </div>

        <Hr/>
        
        <p class="font-semibold mt-4" :class="[accountData.businessKybDetails.kycStatus && accountData.businessKybDetails.kycStatus.toLowerCase()]" v-if="accountData.businessKybDetails && accountData.businessKybDetails.kycStatus">KYC status: {{accountData.businessKybDetails && accountData.businessKybDetails.kycStatus}}</p>

        <div v-if="accountData.businessKybDetails">
            <Button p="p-2" class="my-2" text="Decline Business info" @click="handleShowDeclineModal('kyb')" :disabled="(accountData.businessKybDetails && !accountData.businessKybDetails.businessRegistrationInfo)"  v-if="(accountData.businessKybDetails.businessRegistrationInfo && accountData.businessKybDetails.businessRegistrationInfo.verificationStatus == 'PENDING') ||(accountData.businessKybDetails.businessRegistrationInfo &&  accountData.businessKybDetails.businessRegistrationInfo.verificationStatus == 'IN_REVIEW')"/>
            <div v-if="accountData.businessKybDetails.businessRegistrationInfo">
                <p class="font-semibold mt-4">Registration Info:</p>
                <p class="mt-2">Reg. Number: {{accountData.businessKybDetails.businessRegistrationInfo.registrationNumber }}</p>
                <p class="mt-2">Verification status: <span :class="[accountData.businessKybDetails.businessRegistrationInfo && accountData.businessKybDetails.businessRegistrationInfo.verificationStatus.toLowerCase()]"> {{accountData.businessKybDetails.businessRegistrationInfo.verificationStatus }}</span></p>
                <p class="mt-2">Business type: {{accountData.businessKybDetails.businessRegistrationInfo.registeredBusinessType}}</p>
                <p class="mt-2">Registered Business name: {{accountData.businessKybDetails.businessRegistrationInfo.registeredBusinessName}}</p>
                <p class="mt-2">Address: {{ accountData.businessKybDetails.businessRegistrationInfo.address.main }} </p>
                <p class="mt-4" v-if="accountData.businessKybDetails.businessRegistrationInfo.declinedReason && accountData.businessKybDetails.businessRegistrationInfo.verificationStatus === 'DECLINED'">Declined Reason: {{ accountData.businessKybDetails.businessRegistrationInfo.declinedReason }} </p>
                <p class="flex items-center mt-3">Incoporation Certificate: <SecondaryButton text="View file" class="ml-2" @click="downloadItem(accountData.businessKybDetails.businessRegistrationInfo.incorporationCertificateFileId)"/></p>
                <p class="flex items-center mt-3">Operating License: <SecondaryButton text="View file" class="ml-2" @click="downloadItem(accountData.businessKybDetails.businessRegistrationInfo.operatingLicenseFileId)"/></p>
                <p class="flex items-center mt-3">Proof of adress: <SecondaryButton text="View file" class="ml-2" @click="downloadItem(image)" v-for="image in accountData.businessKybDetails.businessRegistrationInfo.proofOfAddressFileId" :key="image"/></p>
                <p class="flex items-center mt-3">Memart: <SecondaryButton text="View file" class="ml-2" @click="downloadItem(image)" v-for="image in accountData.businessKybDetails.businessRegistrationInfo.memartFileId" :key="image"/></p>
                <Hr/>
            </div>
        </div>
       
        <p class="font-semibold mt-4">Directors</p>
        <div class="grid mt-3 grid-cols-4" v-if="accountData.businessKybDetails" >
            <div class="source p-4" v-for="director in accountData.businessKybDetails.businessDirector" :key="director.id">
                <Button text="Decline director" p="p-2" class="my-2" v-if="director.verificationStatus == 'IN_REVIEW' || director.verificationStatus == 'PENDING'" @click="handleShowDeclineModal('director'),directorId = director.id"/>
                <p>Name: <strong>{{director.firstName}} {{ director.middleName}} {{director.lastName}}</strong> </p>
                <p class="mt-2">Email: {{director.email}}</p>
                <p class="mt-2">Verification status: <span  :class="[director.verificationStatus && director.verificationStatus.toLowerCase()]">{{director.verificationStatus}}</span> </p>
                <p class="mt-2">DOB: {{director.dob}}</p>
                <p class="mt-2">Phone: {{director.phoneNumber}}</p>
                <p class="mt-2">Nationality: {{director.nationality}}</p>
                <p class="mt-2">BVN: {{director.bvn}}</p>
                <p class="mt-2">Address: {{ director.address.main }} </p>
                <p class="mt-2">Job Title: {{director.jobTitleOrRole }}</p>
                <p class="mt-2">ID card Issue data: {{director.idCardIssueDate  }}</p>
                <p class="mt-2">ID card expiry date: {{director.idCardExpiryDate}}</p>
                <p class="mt-2 font-semibold">Attachments:  </p>
                <div class="flex items-center flex-wrap gap-2 mt-1">
                    <SecondaryButton text="View Id" @click="downloadItem(director.identityDocFileId)"/>
                    <SecondaryButton text="View address"  @click="downloadItem(director.proofOfAddressFileId)"/>
                </div>
            </div>
        </div>
    </Card>
    <div>
        <div v-if="showUpdateKybModal">
            <UpdateKyb :wireInfo="accountData.businessKybDetails.businessRegistrationInfo" :display="showUpdateKybModal" @close="showUpdateKybModal = false" @success="handleKybSuccess"/>
        </div>
        <Modal :display="showBrokerAccessModal" @close="showBrokerAccessModal = false">
            <p class="text-lg font-semibold">Select access status</p>
            <Select :options="brokerAccessOptions" v-model="brokerAccess" class="mt-3"/>
            <Button :loading="brokerAccessLoading" text="Okay" class="mt-8" width="w-full" @click="updateBrokerAccess"/>
        </Modal>
        <!-- force eligibilty update -->
        <Modal :display="showForceUpdateModal" @close="showForceUpdateModal = false">
            <p class="text-lg font-semibold">Select Force eligibiliity status</p>
            <Select :options="forceUpdateOptions" v-model="forceBrokerUpdate" class="mt-3"/>
            <Button :loading="forceLoading" text="Okay" class="mt-8" width="w-full" @click="forceBrokerEligibility"/>
        </Modal>
        <!-- forced senders kyc -->
        <Modal :display="showUpdateSendersKyc" @close="showUpdateSendersKyc= false">
            <p class="text-lg font-semibold">Force senders KYC</p>
            <Select :options="forceUpdateOptions" v-model="forceSendersUpdate" class="mt-3"/>
            <Button :loading="forceLoading" text="Okay" class="mt-8" width="w-full" @click="updateSendersKyc"/>
        </Modal>
        <RateDiscount :display="showDiscountModal" @success="showDiscountModal = false" @close="showDiscountModal = false"/>
        <right-sidebar v-if="showBusinessInfo" display @close="showBusinessInfo = false"  title="Business Registration">
            <template #body>
                <EnterBusinessInfo :businessInfoExt="accountData.businessKybDetails.businessRegistrationInfo" @next="handleAddBusinessInfo"  :wireAcctData="accountData.wireAccount" />
            </template>
        </right-sidebar>
        <right-sidebar v-if="showAddDirective" display @close="showAddDirective = false"  title="Add directors">
            <template #body>
                <AddDirectorsDetails :extWireDirectors="accountData.businessKybDetails && accountData.businessKybDetails.businessDirector" @complete="handleDirectorDetails" :wireAcctData="accountData.wireAccount" />
            </template>
        </right-sidebar>
        <right-sidebar  display v-if="showDeclinedModalforKyb" :title="declineType"         @close="showDeclinedModalforKyb = false">
            <template #body>
                <div class="p-4">
                    <Input required v-model="declinedReason" placeholder="declined reason"/>
                    <Button :loading="declineLoading" width="w-full" text="Submit" class="mt-8" @click="declineType == 'director' ? declineDirector() : declineKyb()"/>
                </div>
            </template>
        </right-sidebar>
    </div>
  </div>
</template>

<script>
import {VIEW_ACCOUNT_DETAILS, FORCED_BROKER_ELIGIBILITY, UPDATE_BROKER_ACCESS_STATUS, DECLINE_DIRECTOR, DECLINE_KYB, UPDATE_SENDERS_FORCED_KYB } from '@/utils/api/wire'
import {Button, SecondaryButton } from '@/UI/Button'
import {Modal, RightSidebar} from '@/UI/Modals'
import AddDirectorsDetails from './OTC/AddDirectorsDetails.vue'
import {mapActions} from 'vuex'
import EnterBusinessInfo from './OTC/EnterBusinessInfo.vue'
import {Select} from '@/UI/Input'
import {Input} from '@/UI/Input'
import RateDiscount from './RateDiscount'
import UpdateKyb from './UpdateKyb'
import Hr from '@/UI/Hr'
export default {
    components:{
        Button,
        Hr, 
        SecondaryButton,
        RightSidebar,
        Modal,
        Select,
        UpdateKyb,
        RateDiscount,
        AddDirectorsDetails,
        EnterBusinessInfo,
        Input
    },
    props:{
        accountData:{
            type: Object,
            required: true,
            default:() => ({})
        },
        businessRegistrationInfo:{
            type: Object,
            required: true,
            default:() =>{}
        },
        businessDirector:{
            type: Array,
            required: true,
            default: () => []
        }
    },
    data(){
        return{
            loading: false,
            forceLoading: false,
            showBusinessInfo: false,
            showAddDirective: false,
            showDeclinedModalforKyb: false,
            declineType:'',
            directorId:'',
            brokerAccess:'',
            declinedReason:'',
            showForceUpdateModal: false,
            showDiscountModal: false,
            declineLoading: false,
            brokerAccessLoading: false,
            showBrokerAccessModal: false,
            brokerAccessOptions:['APPROVED', 'DECLINED', 'NONE'],
            forceUpdateOptions: ['ON','OFF', 'NEUTRAL'],
            forceBrokerUpdate:'',
            showUpdateKybModal: false,
            forceSendersUpdate:'',
            showUpdateSendersKyc: false
        }
    },
    methods:{
        ...mapActions('notification', ['showAlert']),
        ...mapActions('loading', ['setLoading']),
        ...mapActions('wire', ['getCurrencyMappings']),
        // getAccountDetails(){
        //     this.loading = true
        //     VIEW_ACCOUNT_DETAILS(this.$route.params.id, this.$route.query.wireAccountId)
        //         .then(({data}) => {
        //         this.businessRegistrationInfo = data.data.businessKybDetails.businessRegistrationInfo
        //         this.businessDirector= data.data.businessKybDetails.businessDirector
        //         // this.accountData = data.data
        //         this.loading = false
        //     }).catch(() => {
        //         this.loading = false
        //     })
        // },
        updateBrokerAccess(){
            this.brokerAccessLoading = true;
            UPDATE_BROKER_ACCESS_STATUS({
                wireAccountId: this.accountData.wireAccount.id,
                brokerAccessStatus: this.brokerAccess
            })
            .then(() => {
                this.showAlert({
                    display: true,
                    description:'Update success',
                    title:'Success',
                    type: 'success'
                })
                this.brokerAccessLoading  = false;
                this.showBrokerAccessModal = false;
                this.$emit('updateKyb')
                // this.getAccountDetails()
            }).catch(() => {
                this.brokerAccessLoading  = false
            })
        },
        forceBrokerEligibility(){
            this.forceLoading = true;
            FORCED_BROKER_ELIGIBILITY({
                wireAccountId: this.accountData.wireAccount.id,
                forcedBrokerAccessEligibilityStatus: this.forceBrokerUpdate
            }).then(() => {
                    this.showAlert({
                        display: true,
                        description:'Update success',
                        title:'Success',
                        type: 'success'
                    })
                    this.forceLoading = false;
                    this.$emit('updateKyb')
                    // this.getAccountDetails()
                    this.showForceUpdateModal = false
                }).catch(() => {
                    this.forceLoading = false
                })
        },
        declineDirector(){
        this.declineLoading = true;
        DECLINE_DIRECTOR({
            id: this.directorId,
            declinedReason: this.declinedReason
        }).then(() => {
            // this.getAccountDetails();
            this.$emit('updateKyb')
            this.showAlert({
            display: true,
            description:'Update successful',
            title:'Success',
            type: 'success'
        })
            this.declineLoading = false;
            this.showDeclinedModalforKyb = false
        }).catch(() => {
            this.declineLoading = false
        })
      },
        declineKyb(){
            this.declineLoading = true;
            DECLINE_KYB({
                id: this.accountData.businessKybDetails.businessRegistrationInfo.id,
                declinedReason: this.declinedReason
            }).then(() => {
                // this.getAccountDetails();
                this.$emit('updateKyb')
                this.showAlert({
                display: true,
                description:'Update successful',
                title:'Success',
                type: 'success'
            })
                this.declineLoading = false;
                this.showDeclinedModalforKyb = false
            }).catch(() => {
                this.declineLoading = false
            })
        },
      downloadItem(id) {
            window.open(
            `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
            "_blank" // open in a new window.
            );
      },
        handleAddDirectives(){
        if(this.businessRegistrationInfo && this.businessRegistrationInfo.id){
            this.showAddDirective = !this.showAddDirective
        }
      },
      handleKybSuccess(){
        this.showUpdateKybModal = false;
        this.$emit('updateKyb')
        // this.getAccountDetails()
      },
    disableApproveKyc(){
        if((this.accountData.businessKybDetails && !this.accountData.businessKybDetails.businessRegistrationInfo) || (this.accountData.businessKybDetails && !this.accountData.businessKybDetails.businessDirector.length)){
            return true
        }
    //    if(this.accountData.businessKybDetails && (this.accountData.businessKybDetails &&  this.accountData.businessKybDetails.businessRegistrationInfo.registeredBusinessType == 'LLC') && (this.accountData.businessKybDetails && this.accountData.businessKybDetails.businessDirector.length < 2) ){
    //         return true
    //     }
      },
      handleAddBusinessInfo(){
        this.showBusinessInfo = false;
        this.showAlert({
            display: true,
            description:'Update successful',
            title:'Success',
            type: 'success'
        })
        this.$emit('updateKyb')
        // this.getAccountDetails();
      },
      handleDirectorDetails(){
        this.showAddDirective = false
        this.showAlert({
            display: true,
            description:'Update successfull',
            title:'Success',
            type: 'success'
        })
        this.$emit('updateKyb')
        // this.getAccountDetails();
      },
      handleShowDeclineModal(type){
        this.declineType = type;
        this.showDeclinedModalforKyb = true
      },
      handleBusinessInfo(){
        this.showBusinessInfo = !this.showBusinessInfo
      },
    //   disableApproveKyc(){
    //    if((this.accountData.businessKybDetails && !this.accountData.businessKybDetails.businessRegistrationInfo) || (this.accountData.businessKybDetails && !this.accountData.businessKybDetails.businessDirector.length)){
    //     return true
    //    }
    //    if(this.accountData.businessKybDetails && (this.accountData.businessKybDetails &&  this.accountData.businessKybDetails.businessRegistrationInfo.registeredBusinessType == 'LLC') && (this.accountData.businessKybDetails && this.accountData.businessKybDetails.businessDirector.length < 2) ){
    //         return true
    //    }
    //   },
      updateSendersKyc(){
        this.forceLoading = true;
        UPDATE_SENDERS_FORCED_KYB({
            data: {
                forcedKycStatus: this.forceSendersUpdate,
                wireSenderProfileId: this.$route.params.wireAccountSendersId
            },
            wireAccountId: this.$route.query.wireAccountId
        }).then(() => {
            this.forceLoading = false;
            this.showUpdateSendersKyc = false;
            this.showAlert({
                    display: true,
                    description:'Update success',
                    title:'Success',
                    type: 'success'
                })
            this.$emit('updateKyb')
        }).catch(() => {
            this.forceLoading = false;
        })
      }
    }
}
</script>